import React, { useState } from 'react';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CloseIcon } from '@swibeco/ui';
import { Box } from '@chakra-ui/react';
import { useTheme } from '@swibeco/shared';
import HeaderItem from '../HeaderItem';
import { Badge } from '../Badge';
import * as Styles from './styles/MobileSearch.styles';
import SearchInput from './SearchInput';

const MobileSearch = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const theme = useTheme();
  const toggle = () => setIsOpen(!isOpen);

  return (
    <>
      <HeaderItem>
        <Styles.IconToggleButton
          data-testid="header-search-toggle"
          onClick={toggle}
        >
          <Badge>
            <FontAwesomeIcon
              icon={faSearch}
              size="lg"
              color={theme.colors.default.black}
            />
            <span className="background" />
          </Badge>
        </Styles.IconToggleButton>
      </HeaderItem>
      {isOpen && (
        <Styles.SearchPanel data-testid="header-search-panel">
          <Box pt="24px" w="full" mr="10px">
            <SearchInput />
          </Box>
          <Styles.IconToggleButton
            data-testid="header-search-panel-close"
            onClick={toggle}
          >
            <CloseIcon />
          </Styles.IconToggleButton>
        </Styles.SearchPanel>
      )}
    </>
  );
};

export default MobileSearch;
