import { breakpoints, styled } from '@swibeco/shared';
import { Link } from '@swibeco/ui';

export const SearchWrapper = styled.div`
  position: relative;
  z-index: 50;

  ${breakpoints.lg_max} {
    width: 100%;
  }
`;

export const SearchFieldWrapper = styled.div`
  display: flex;
  border-radius: 50px;
  border: 1px solid ${({ theme }) => theme.colors.default.middle};
  background-color: ${({ theme }) => theme.colors.default.white};
  width: 220px;

  ${breakpoints.lg_max} {
    width: 100%;
  }

  &:focus {
    outline: none;
  }
`;

export const IconWrapper = styled.span`
  margin-left: 10px;
  display: flex;
  align-items: center;
`;

export const SearchInput = styled.input`
  border: none;
  padding: 8px 10px;
  border-radius: 50px;
  font-size: 17px;
  width: 100%;

  ${breakpoints.lg_max} {
    height: 36px;
  }

  &:focus {
    outline: none;
  }
`;

export const SearchResultsPanel = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  margin-top: 2px;
  border-radius: 6px;
  border: 1px solid ${({ theme }) => theme.colors.default.middle};
  background-color: ${({ theme }) => theme.colors.default.white};
  width: 100%;
  box-shadow: 0px 3px 6px 0px ${({ theme }) => theme.colors.shadow.primary};
  ${breakpoints.lg} {
    width: 200%;
    left: 3px;
  }
`;

export const AllResultsLink = styled(Link)`
  display: block;
  padding: 10px 15px !important;
  text-align: center;
  color: ${({ theme }) => theme.colors.primary.dark};
  font-size: 17px !important;
  font-weight: bold;
  text-decoration: none;
  margin: 12px 6px 5px 6px;
  border-top: 1px solid ${({ theme }) => theme.colors.default.main};
  &:hover {
    color: ${({ theme }) => theme.colors.primary.dark};
    background-color: ${({ theme }) => theme.colors.default.main} !important;
    border-radius: 5px;
  }
`;
