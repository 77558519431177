import React from 'react';
import { Link as ReactRouterLink } from 'react-router-dom';
import { styled, breakpoints } from '@swibeco/shared';
import Chevron, { ChevronDirection } from './iconography/Chevron';
import Arrow, { ArrowDirection } from './iconography/Arrow';
import { EnumLinkProps, ColorProps, StyledColorProps } from '@swibeco/types';

type StyledLinkProps = StyledColorProps & {
  underlinePopup?: boolean;
  as?: React.ElementType;
};

const LinkComponent = styled('a', {
  shouldForwardProp: (prop) =>
    !['variantColor', 'underlinePopup', 'as', 'variant'].includes(prop),
})<StyledLinkProps>`
  border: none;
  color: ${({ theme, variant, variantColor }) =>
    theme.colors[variant][variantColor]};
  cursor: pointer;
  font-size: 17px;
  font-weight: 700;
  padding: 0 !important;
  background: none !important;
  text-decoration: ${({ underlinePopup }) =>
    underlinePopup ? 'underline' : 'none'};
  display: flex;
  align-items: center;

  ${breakpoints.lg} {
    font-size: 18px;
  }

  &:hover {
    text-decoration: ${({ underlinePopup }) =>
      underlinePopup ? 'underline' : 'none'};
  }
`;

const IconBefore = styled.span`
  margin-right: 10px;
  &:hover {
    opacity: 0.9;
  }
`;

const IconAfter = styled.span`
  margin-left: 10px;
  &:hover {
    opacity: 0.9;
  }
`;

const SwibecoChevron = styled(Chevron)`
  &.inline {
    display: inline-block;
  }
`;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type LinkProps<T = any> = Omit<React.HTMLProps<T>, 'color' | 'type'> &
  ColorProps & {
    type?: EnumLinkProps;
    className?: string;
    component?: T;
    toggled?: boolean;
    to?: string;
  };

const Link = React.forwardRef(
  (
    {
      component = 'a',
      className,
      children,
      type,
      toggled,
      color,
      ...rest
    }: LinkProps,
    ref
  ) => {
    let as;
    if (rest.to) {
      as = ReactRouterLink;
    } else if (rest.href) {
      as = 'a';
    } else {
      as = component;
    }
    return (
      <LinkComponent
        underlinePopup={type === EnumLinkProps.UnderlinePopup}
        variant={color?.variant || 'primary'}
        variantColor={color?.variantColor || 'main'}
        /* @todo
          when 100% moved to React, Link should be the Router Link
        */
        as={as}
        className={className}
        ref={ref}
        {...rest}
      >
        {type === EnumLinkProps.Back && (
          <IconBefore>
            <Arrow color={color} direction={ArrowDirection.Left} />
          </IconBefore>
        )}

        {children}

        {type === EnumLinkProps.NewPage && (
          <IconAfter>
            <Arrow color={color} direction={ArrowDirection.Right} />
          </IconAfter>
        )}

        {type === EnumLinkProps.Expand && (
          <IconAfter>
            <SwibecoChevron
              className="inline"
              color={color}
              direction={toggled ? ChevronDirection.Up : ChevronDirection.Down}
            />
          </IconAfter>
        )}
      </LinkComponent>
    );
  }
);

export default Link;
