import React, { Fragment } from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import {
  DealCardSkeletonLoading,
  MainContentWrapper,
} from '@swibeco/shared-web';
import { arrayFrom } from '@swibeco/shared';
import { Box, Flex } from '@chakra-ui/react';
import * as ProductsStyles from '../Products.styles';

const PRODUCT_NUMBERS = [3, 5, 2];

const ProductsSkeletonLoading = () => (
  <ProductsStyles.ProductsSegment>
    <MainContentWrapper>
      <Skeleton height="41px" width="260px" className="mb-4" />
      <Box pb="24px">
        {arrayFrom(PRODUCT_NUMBERS.length).map((v) => (
          <Fragment key={PRODUCT_NUMBERS[v]}>
            <Skeleton height="26px" width="195px" className="mb-3" />
            <Flex gap="18px" alignItems="center" marginBottom="72px">
              {arrayFrom(PRODUCT_NUMBERS[v]).map((i) => (
                <DealCardSkeletonLoading key={i} />
              ))}
            </Flex>
          </Fragment>
        ))}
      </Box>
    </MainContentWrapper>
  </ProductsStyles.ProductsSegment>
);

export default ProductsSkeletonLoading;
