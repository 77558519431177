import React from 'react';
import { useSelector } from 'react-redux';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { GlobalSelectors } from '@swibeco/shared';
import { Banner } from '../../components/Banner';
import { Products } from '../../components/Products';
import { Slider } from '../../components/Slider';
import { TopCategoryMenu } from '../../components/TopCategoryMenu';
import { ScrollTopButton } from '../../components/ScrollTopButton';
import { InspireMe } from '../../components/InspireMe';
import { AxaBanner } from '../../components/Banner/AxaBanner';
import { Brands } from '../../components/Brands';
import { CompleteActivationModal } from '../../components/CompleteActivationModal';
import * as Styles from './HomePage.styles';
import { BottomCategoriesMenu } from '../../components/BottomCategoriesMenu';

const HomePage = () => {
  const { t } = useTranslation();
  const isDealerAxa = useSelector(GlobalSelectors.company.isDealerAxa);

  return (
    <HelmetProvider>
      <Helmet>
        <title>{t('core.ecommerce.page.title')}</title>
      </Helmet>
      <Styles.HomePageWrapper id="homepage">
        <TopCategoryMenu />
        <Slider />
        <Banner />
        <Products />
        {/* @todo commented as it's not programmed for the first version */}
        {/* <AffordableDeals /> */}
        {isDealerAxa && <AxaBanner />}
        <BottomCategoriesMenu />
        <Brands />
        <InspireMe />
      </Styles.HomePageWrapper>
      <ScrollTopButton />
      <CompleteActivationModal />
    </HelmetProvider>
  );
};

export default HomePage;
