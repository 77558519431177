import { GlobalSelectors, useTheme } from '@swibeco/shared';
import { EnumProductType, Product, ProductVariant } from '@swibeco/types';
import { Button } from '@swibeco/ui';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ButtonProps as RSButtonProps } from 'reactstrap';
import { useMutation } from '@tanstack/react-query';
import { syliusApi } from '@swibeco/ecommerce';
import { selectors as coreSelectors } from '@swibeco/core';
import { useSelector } from 'react-redux';
import { ReactComponent as CartIcon } from '../../assets/images/cart.svg';
import { ReactComponent as NewTabIcon } from '../../assets/images/new-tab.svg';

type ProductButtonProps = Pick<Product, 'type'> &
  Partial<Pick<Product, 'isPrivate' | 'isConfigurable'>> & {
    onAddItem: (productVariant: ProductVariant) => void;
    onOpenVoucherModal: () => void;
    disabled?: boolean;
    productRoute: string;
    slug: string;
    promoLink?: string;
    id: number;
  };

export const ButtonWithHover = ({
  children,
  disabled,
  ...rest
}: RSButtonProps) => {
  const theme = useTheme();
  return (
    <Button
      thin
      color="primary"
      lightColor={false}
      disabled={disabled}
      disabledColor={theme.colors.default.white}
      {...rest}
    >
      {children}
    </Button>
  );
};

export const ProductItemButton = ({
  type,
  onAddItem,
  onOpenVoucherModal,
  disabled,
  isPrivate,
  productRoute,
  isConfigurable,
  id,
  slug,
}: ProductButtonProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const locale = useSelector(coreSelectors.getLocale);
  const userType = useSelector(GlobalSelectors.user.getUserType);

  const { mutate: getProductVariantsBySlug } = useMutation<ProductVariant[]>({
    mutationFn: () => syliusApi.getProductVariantsBySlug(slug, locale),
    onSuccess: (data) => {
      window.open(
        data[0].variantChannelInfos[userType!.toUpperCase()].externalLink,
        'noopener',
        'noreferrer'
      );
    },
  });

  const canBeAddedtoCart = (type: EnumProductType) =>
    type === EnumProductType.Physical || type === EnumProductType.Voucher;

  if (isPrivate) {
    return canBeAddedtoCart(type) ? (
      <ButtonWithHover
        data-testid="deal-item-button-default"
        disabled={disabled}
        // onClick={onAddItem}
      >
        <CartIcon />
      </ButtonWithHover>
    ) : (
      <ButtonWithHover
        data-testid="deal-item-button-default"
        disabled={disabled}
        onClick={() => navigate(productRoute)}
      >
        {t('core.deal_item.button.details')}
      </ButtonWithHover>
    );
  }
  switch (type) {
    case EnumProductType.GenericCode:
      return (
        <ButtonWithHover disabled={disabled} onClick={onOpenVoucherModal}>
          {t('core.deal_item.button.code')}
        </ButtonWithHover>
      );
    case EnumProductType.LandingPage: {
      return (
        <ButtonWithHover
          data-testid="deal-item-button-promo-link"
          disabled={disabled}
          onClick={() => getProductVariantsBySlug()}
        >
          <NewTabIcon />
        </ButtonWithHover>
      );
    }
    case EnumProductType.Physical:
    case EnumProductType.Voucher:
    case EnumProductType.SwipointsPack:
    case EnumProductType.DropShipping:
      return isConfigurable ? (
        <ButtonWithHover
          data-testid="deal-item-button-add-to-cart"
          disabled={disabled}
          onClick={() => navigate(productRoute)}
        >
          {t('core.product_item.button.multiple_variants')}
        </ButtonWithHover>
      ) : (
        <ButtonWithHover
          data-testid="deal-item-button-add-to-cart"
          disabled={disabled}
          onClick={() => onAddItem({ id } as ProductVariant)}
        >
          <CartIcon />
        </ButtonWithHover>
      );
    default:
      return (
        <ButtonWithHover
          data-testid="deal-item-button-default"
          disabled={disabled}
          onClick={() => navigate(productRoute)}
        >
          {t('core.deal_item.button.details')}
        </ButtonWithHover>
      );
  }
};
