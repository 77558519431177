import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import {
  EnumLinkProps,
  ColorVariantDescription,
  ThemeColorSection,
} from '@swibeco/types';
import { Text, Link, QuestionMark, Popover } from '@swibeco/ui';
import { useColorVariant, useNumberFormatter } from '@swibeco/shared';
import { useSwipoints } from '@swibeco/core';
import { Box, Flex } from '@chakra-ui/react';
import * as Styles from './SwipointsBanner.styles';
import swipoints from '../../../assets/images/swipoints_animated.gif';

interface SwipointsBannerInterface {
  swipointsBalance: number;
  isBasket?: boolean;
}

const SwipointsBanner = ({
  swipointsBalance,
  isBasket = false,
}: SwipointsBannerInterface) => {
  const { t } = useTranslation();
  const mainColor = useColorVariant('primary', 'main');
  const numberFormatter = useNumberFormatter('decimal');
  const currencyFormatter = useNumberFormatter();
  const { swipointsToCashConversion } = useSwipoints(
    swipointsBalance,
    swipointsBalance
  );

  return (
    <Styles.CreditRedeem isBasket={isBasket}>
      <Flex
        w="full"
        justify="start"
        flex={2}
        align={isBasket ? 'start' : 'center'}
      >
        <Box
          mr="2"
          minW="54px"
          position="relative"
          top={isBasket ? '-16px' : undefined}
        >
          <img height={61} width={54} src={swipoints} alt="swipoints" />
        </Box>
        <Box flex={isBasket ? 5 : undefined}>
          {isBasket && (
            <Text bold mr="2" color="secondary.decorationBlue">
              {`${t('core.ecommerce.homepage.banner.good_news')},`}
            </Text>
          )}
          <Text bold={!isBasket} marginRight="2">
            <Trans
              i18nKey={
                isBasket
                  ? 'core.checkout.banner.swipoints'
                  : 'core.ecommerce.homepage.banner.swipoints'
              }
              components={{
                a: <Text as="span" color="secondary.decorationBlue" />,
              }}
              values={{
                swipoints: numberFormatter(swipointsBalance),
                equivalent: currencyFormatter(swipointsToCashConversion),
              }}
            />
          </Text>
        </Box>
        <Popover
          placement="top"
          popoverTrigger={
            <QuestionMark id="swipoints-question-mark" width={20} height={20} />
          }
        >
          <Text>
            <strong>
              {t('core.checkout.funnel.swipoints_payment.information.title')}
            </strong>
          </Text>
          <Text>
            {t(
              'core.checkout.funnel.swipoints_payment.information.description'
            )}
          </Text>
        </Popover>
      </Flex>
      {!isBasket && (
        <Styles.BannerLinkWrapper>
          <Styles.BannerHoverBehavior
            color={
              mainColor as ColorVariantDescription<ThemeColorSection> & string
            }
            disabled={false}
          >
            <Link
              type={EnumLinkProps.NewPage}
              href={t('ecore.ecommerce.homepage.banner.swipoints_link')}
              target="_blank"
              color={mainColor}
            >
              {t('core.ecommerce.homepage.banner.more_information')}
            </Link>
          </Styles.BannerHoverBehavior>
        </Styles.BannerLinkWrapper>
      )}
    </Styles.CreditRedeem>
  );
};

export default SwipointsBanner;
