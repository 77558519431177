import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { Row } from 'reactstrap';
import { MainContentWrapper } from '@swibeco/shared-web';
import { useIsTablet } from '@swibeco/core';
import { homeStoreApi } from '@swibeco/ecommerce';
import { Brand as BrandType } from '@swibeco/types';
import { ECOMMERCE_BRAND_ROOT } from '@swibeco/shared';
import { Brand } from './BrandItem';
import * as Styles from './Brands.styles';

const MOBILE_PAGE_SIZE = 40;
const DESKTOP_PAGE_SIZE = 120;
const TOTAL_BRANDS = 1000;

const Brands = () => {
  const { t } = useTranslation();
  const [showMore, setShowMore] = useState(false);
  const [showAll, setShowAll] = useState(false);
  const isTablet = useIsTablet();
  const showMoreBrands = () => setShowMore(true);
  const loadAllBrands = () => setShowAll(true);

  const { data: brands, isLoading } = useQuery({
    queryKey: ['brands', isTablet],
    queryFn: () => {
      const pageSize = isTablet ? MOBILE_PAGE_SIZE : DESKTOP_PAGE_SIZE;
      // fetch all brands when opened
      return homeStoreApi.getAllBrands(showAll ? TOTAL_BRANDS : pageSize);
    },
    placeholderData: keepPreviousData,
  });

  const formattedBrands = useMemo(() => {
    const result: Pick<BrandType, 'logo' | 'slug' | 'name'>[][] = [];

    if (!brands) return result;

    for (let i = 0; i < brands.data.length; i += 20) {
      const section = brands.data.slice(i, i + 20);
      if (
        (!showAll && i >= DESKTOP_PAGE_SIZE) ||
        (!showAll && isTablet && i >= MOBILE_PAGE_SIZE)
      ) {
        break;
      }
      result.push(section);
    }

    return result;
  }, [brands, showAll, isTablet]);

  return brands?.data.length ? (
    <Styles.BrandsWrapper limitHeight={!showMore}>
      <MainContentWrapper>
        <Styles.Title as="h3">
          {t('core.ecommerce.homepage.discover_brands')}
        </Styles.Title>
        <Row>
          {formattedBrands.map((brandsGroup) => (
            <Styles.StyledCol
              xs={6}
              sm={6}
              md={4}
              lg={2}
              key={brandsGroup?.[0].slug}
            >
              {brandsGroup.map((brand) => (
                <Styles.BrandItem key={brand.slug}>
                  <Brand
                    url={`${ECOMMERCE_BRAND_ROOT}/${brand.slug}`}
                    name={brand.name}
                  />
                </Styles.BrandItem>
              ))}
            </Styles.StyledCol>
          ))}
        </Row>
        {!showMore && (
          <Styles.ButtonWrapper>
            <Styles.StyledButton
              lightColor
              thin
              onClick={showMoreBrands}
              disabled={isLoading}
            >
              {t('core.ecommerce.homepage.discover_brands.see_all')}
            </Styles.StyledButton>
          </Styles.ButtonWrapper>
        )}
        {showMore && !showAll && (
          <Styles.LoadAllWrapper>
            <Styles.StyledButton
              lightColor
              thin
              onClick={loadAllBrands}
              disabled={isLoading}
            >
              {t('core.ecommerce.homepage.discover_brands.load_all')}
            </Styles.StyledButton>
          </Styles.LoadAllWrapper>
        )}
      </MainContentWrapper>
    </Styles.BrandsWrapper>
  ) : null;
};

export default Brands;
