import React, {
  createContext,
  useEffect,
  useState,
  useContext,
  useCallback,
} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useQueryClient } from '@tanstack/react-query';
import { CurrentCompanyInformationType, UserType } from '@swibeco/types';
import { GlobalSelectors, GlobalActions } from '@swibeco/shared';
import { useGetCurrentCompanyInformation, useGetCurrentUser } from '../hooks';
import buildPermissionsForUser from '../permissions';

const userQueryKey = 'current-user';
const companyQueryKey = 'current-company';

type UserContextProps = {
  user?: UserType;
  isSuccess?: boolean;
  isLoading?: boolean;
  refetchUser?: () => void;
  clearUser?: () => void;
};

type CompanyContextProps = {
  company?: CurrentCompanyInformationType;
  isFetching?: boolean;
  isLoading?: boolean;
  isSuccess?: boolean;
  refetchCompany?: () => void;
  clearCompany?: () => void;
};

export const UserContext = createContext({} as UserContextProps);
export const CompanyContext = createContext({} as CompanyContextProps);

export const CurrentUserCompanyProvider = ({
  children,
}: React.PropsWithChildren) => {
  const { Provider: User } = UserContext;
  const { Provider: Company } = CompanyContext;

  const [userContext, setUserContext] = useState<UserContextProps>({});
  const [companyContext, setCompanyContext] = useState<CompanyContextProps>({});

  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const isAuthenticated = useSelector(
    (state: { security: Record<string, any> }) => state.security?.authenticated
  );
  const companyMainColor = useSelector(GlobalSelectors.company.getMainColor);

  const clearUserCache = useCallback(() => {
    if (queryClient) {
      queryClient.removeQueries({ queryKey: [userQueryKey], exact: true });
    }
  }, [queryClient]);

  const clearCompanyCache = useCallback(() => {
    if (queryClient) {
      queryClient.removeQueries({ queryKey: [companyQueryKey], exact: true });
    }
  }, [queryClient]);

  const {
    data: userData,
    refetch: userRefetch,
    isSuccess: userIsSuccess,
    isLoading: userIsLoading,
  } = useGetCurrentUser(userQueryKey, {
    enabled: !!isAuthenticated,
  });

  const {
    data: companyData,
    refetch: companyRefetch,
    isFetching,
    isLoading,
  } = useGetCurrentCompanyInformation(companyMainColor, companyQueryKey);

  useEffect(() => {
    if (isAuthenticated) {
      companyRefetch();
    }
  }, [isAuthenticated, companyRefetch]);

  useEffect(() => {
    if (!isAuthenticated) {
      clearUserCache();
    }
  }, [isAuthenticated, clearUserCache]);

  useEffect(() => {
    if (userData) {
      setUserContext({
        user: {
          ...userData,
          ability: userData ? buildPermissionsForUser(userData) : null,
        },
        isLoading: userIsLoading,
        isSuccess: userIsSuccess,
        refetchUser: userRefetch,
        clearUser: clearUserCache,
      });
    } else {
      setUserContext({
        isLoading: userIsLoading,
        isSuccess: userIsSuccess,
        refetchUser: userRefetch,
        clearUser: clearUserCache,
      });
    }
  }, [userIsSuccess, userData, userRefetch, clearUserCache, userIsLoading]);

  useEffect(() => {
    setCompanyContext({
      company: companyData,
      refetchCompany: companyRefetch,
      clearCompany: clearCompanyCache,
      isFetching,
      isLoading,
      isSuccess: true,
    });
    if (companyData) {
      dispatch(GlobalActions.company.setCompany(companyData));
    }
  }, [
    clearCompanyCache,
    companyData,
    companyRefetch,
    isFetching,
    isLoading,
    dispatch,
  ]);

  return (
    <Company value={companyContext}>
      <User value={userContext}>{children}</User>
    </Company>
  );
};

export const CurentUserConsumer = UserContext.Consumer;

export function useCurrentUser() {
  return useContext(UserContext);
}

export function useCurrentCompany() {
  return useContext(CompanyContext);
}
