import React from 'react';
import { faCog } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTheme } from '@swibeco/shared';
import HeaderItem from './HeaderItem';
import { Badge } from './Badge';

const HRAdmin = () => {
  const theme = useTheme();
  return (
    <HeaderItem noBorder>
      <a
        href="/#/admin/company"
        data-testid="hr-admin-btn"
        aria-label="Hr admin"
      >
        <Badge>
          <FontAwesomeIcon
            icon={faCog}
            size="lg"
            color={theme.colors.default.black}
          />
          <span className="background" />
        </Badge>
      </a>
    </HeaderItem>
  );
};

export default HRAdmin;
