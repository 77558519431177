export interface ThemeType {
  colors: {
    primary: {
      decorationLight: string;
      light: string;
      main: string;
      mainLight: string;
      strong: string;
      dark: string;
      footerBlue: string;
      plusBlue: string;
      axaBlue: string;
    };
    secondary: {
      decorationGreen: string;
      decoration: {
        red: {
          light: string;
          palePink: string;
        };
      };
      main: string;
      mainLight: string;
      decorationYellow: string;
      lightYellow: string;
      decorationBlue: string;
      ebonyClay: string;
      decorationRed: string;
      decorationPurple: string;
      decorationOrange: string;
    };
    complementary: {
      light: string;
      red: string;
      lightGreen: string;
    };
    default: {
      white: string;
      black: string;
      light: string;
      main: string;
      middle: string;
      strong: string;
      dark: string;
      backgroundColor: string;
    };
    shadow: {
      primary: string;
      secondary: string;
    };
    initial: {
      main: string;
      light: string;
      strong: string;
      decorationBlue: string;
    };
    legacy?: {
      default: {
        grey: string;
        light: string;
        black: string;
      };
    };
  };
  initial?: {
    colors: {
      primary: ThemeType["colors"]["primary"];
      shadow: ThemeType["colors"]["shadow"];
    };
  };
  properties?: { [key: string]: string };
}

export type ThemeColorSection = keyof Omit<ThemeType["colors"], "legacy">;
export type ThemeColor<T extends ThemeColorSection> =
  keyof ThemeType["colors"][T];

export type ColorVariantDescription<T extends ThemeColorSection> = {
  variant: T;
  variantColor: ThemeColor<T>;
};

export enum ColorVariants {
  Info,
  Danger,
  Success,
  Warning,
}

export enum ShapeVariants {
  Circle,
}

export type ColorProps = {
  color?: ColorVariantDescription<ThemeColorSection>;
};

export type SwiperHeaderProps = {
  titlePadding?: boolean;
  marginTop?: number;
};

export type StyledColorProps = {
  variant: string;
  variantColor: string;
  clickable?: boolean;
};

export enum EnumLinkProps {
  Expand,
  UnderlinePopup,
  NewPage,
  Back,
}

export type Alignment = "start" | "center" | "end";
