import { useCallback } from 'react';
import { useCurrentCompany } from '@swibeco/security';

const useSwipoints = (totalAmount: number, swipointsBalance: number) => {
  /* used isFetching instead of isLoading since we are using placeholderData
    which is always false by design */
  const { company, isFetching } = useCurrentCompany();

  const totalSwipoints = useCallback((): number => {
    if (!isFetching) {
      const swipointsToPay = company?.features?.PAY_WITH_ONLY_SWIPOINTS
        ? totalAmount * 10
        : (totalAmount - 1) * 10;

      return swipointsToPay > swipointsBalance
        ? swipointsBalance
        : swipointsToPay;
    }
    return 0;
  }, [
    isFetching,
    company?.features?.PAY_WITH_ONLY_SWIPOINTS,
    totalAmount,
    swipointsBalance,
  ]);

  const maxPaymentAccepted = totalSwipoints() / 10;
  const swipointsToCashConversion = swipointsBalance / 10 ?? 0;

  return {
    maxPaymentAccepted,
    swipointsToCashConversion,
  };
};

export default useSwipoints;
