import { styled } from '@swibeco/shared';

export const IconToggleButton = styled.button`
  padding: 0;
  border: none;
  background: none;
`;

export const SearchPanel = styled.div`
  background-color: ${({ theme }) => theme.colors.default.main};
  display: flex;
  align-items: center;
  padding: 0 20px;
  position: fixed;
  height: 90px;
  top: 0;
  left: 0;
  right: 0;
  z-index: 99;
  display: flex;
  justify-content: center;
`;
