export interface SwipointsHistoryItem {
  authorCompanyName: string | null;
  authorName: string | null;
  comment: string | null;
  createdAt: string;
  details: string | null;
  distributionDate: string;
  event: SWIPOINT_HISTORY_EVENT;
  id: number;
  quantity: number;
  reason: string | null;
  uuid: string;
}

export interface SwipointsBalanceWithExpiry {
  amount: number;
  expiresAt: string | null;
}

export enum SWIPOINT_HISTORY_EVENT {
  COLLEAGUE_OFFER = "colleague_offer",
  OFFER_COLLEAGUE = "offer_colleague",
  RULE_WELCOME_GIFT = "rule_welcome_gift",
  RULE_BIRTHDAY = "rule_birthday",
  RULE_ANNIVERSARY = "rule_anniversary",
  RULE_JOB_ANNIVERSARY = "rule_job_anniversary",
  RULE_SPECIFIC_DATE = "rule_specific_date",
  ORDER_CANCEL = "order_cancel",
  ORDER_PAYMENT = "order_payment",
  SWIPOINTS_PURCHASE_THROUGH_DEAL = "swipoints_purchase_through_deal",
  GIFT = "gift",
  BIRTH = "birth",
  WEDDING = "wedding",
  JUBILEE = "jubilee",
  BIRTHDAY = "birthday",
  CHRISTMAS = "christmas",
  SWIBECO_GIFT = "swibeco_gift",
  SWIBECO_RULE_GIFT = "swibeco_rule_gift",
  COMPANY_RETURN = "company_return",
  THIRD_PILLAR_TRANSFER = "third_pillar_transfer",
  OTHER = "other",
  V2_MIGRATION = "v2_migration",
  SWIBECO_DEDUCT = "swibeco_deduct",
  BUY = "buy",
  MARKETING = "marketing",
  SAV = "sav",
  CORRECTION = "correction",
  CASHBACK = "cashback",
  TES = "test",
}
