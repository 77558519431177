import {
  SyliusAPI,
  parseHydraResponse,
  AxiosRequestConfig,
  getMappedBrand,
} from '@swibeco/shared';
import {
  AvailableSearchParamValues,
  FilterData,
  FilterParams,
  ProductVariant,
  BackendEvent,
  SyliusBrand,
  UniverseContextParams,
  Product,
  HydraResponse,
} from '@swibeco/types';
import { UniverseParams } from '../hooks/useUniverseParams';
import { isBrandPage, isCategoryPage, isSearchResultsPage } from '../utils';

const acceptJSON = {
  Accept: 'application/json',
};

const createUniverseContextParams = (
  universeParams: UniverseParams
): Partial<UniverseContextParams> => {
  const universeContextParams = {} as UniverseContextParams;

  if (!universeParams) {
    return {};
  }

  if (isCategoryPage(universeParams)) {
    if (universeParams.category !== 'flash-deals') {
      universeContextParams.categorySlug =
        universeParams.subCategory || universeParams.category;
    } else {
      universeContextParams['variants.isFlash'] = 1;
    }
  }

  if (isBrandPage(universeParams)) {
    universeContextParams['brand.translations.slug'] = universeParams.brand;
  }

  if (isSearchResultsPage(universeParams)) {
    universeContextParams.containsQuery = universeParams.search;
    universeContextParams.containsMatch = 1;

    // pagination does not work for search, so we must enforce a higher itemsPerPage than other calls
    universeContextParams.itemsPerPage = 100;
    universeContextParams.page = 1;
  }

  return universeContextParams;
};

const createFilterParams = (
  filterData?: Partial<AvailableSearchParamValues>
): Partial<FilterParams> => {
  const filterParams = {
    withStock: 1,
  } as FilterParams;

  if (filterData) {
    if (filterData.offerType) {
      filterParams.type = filterData.offerType.map(
        (offerType) => offerType.value
      );
    }
    if (filterData.brands) {
      filterParams['brand.translations.slug'] = filterData.brands.map(
        (brand) => brand.value
      );
    }
    if (filterData.reimbursable) {
      filterParams.isCashback = 1;
    }
    if (filterData.buyableWithSwipoints) {
      filterParams.isPurchasableWithSwipoints = filterData.buyableWithSwipoints;
    }
    if (filterData.hasNewOffers) {
      filterParams.newOffers = filterData.hasNewOffers;
    }
    if (filterData.sort) {
      switch (filterData.sort) {
        case 'shortTitle_dt_asc':
          filterParams['order[translations.name]'] = 'asc';
          filterParams.containsDirection = 'asc';
          break;
        case 'shortTitle_dt_desc':
          filterParams['order[translations.name]'] = 'desc';
          filterParams.containsDirection = 'desc';
          break;
        case 'createdAt_asc':
          filterParams['order[id]'] = 'desc';
          filterParams.containsDirection = 'desc';
          break;
        case 'createdAt_desc':
          filterParams['order[id]'] = 'asc';
          filterParams.containsDirection = 'asc';
          break;
        case 'position_asc':
          filterParams['order[productTaxons.position]'] = 'asc';
          filterParams.containsDirection = 'asc';
          break;
        case 'position_desc':
          filterParams['order[productTaxons.position]'] = 'desc';
          filterParams.containsDirection = 'desc';
          break;
        default:
          break;
      }
    }
  }
  return filterParams;
};

export default {
  // Retrieves categories with deals from the Sylius API
  // Uses acceptJSON to specify that the response should be in JSON format otherwise it will be in JSON-LD
  getTaxonsWithProductVariants: async (locale: string) => {
    const response = await SyliusAPI.get('/shop/taxons/with-product-variants', {
      headers: acceptJSON,
      params: { locale },
      // 'X-LOCALE': getCountryCodeByLocale(locale),
    } as AxiosRequestConfig);
    return response.data;
  },

  getTaxonsBySlug: async (categorySlug: string, locale: string) => {
    const response = await SyliusAPI.get(
      `/shop/taxons/by-slug/${categorySlug}`,
      {
        headers: acceptJSON,
        params: { locale },
      }
    );
    return response.data;
  },

  getHydraProductsListing: async (
    universeParams: UniverseParams,
    page: number,
    filterParams: Partial<AvailableSearchParamValues>,
    locale: string,
    overwriteParams?: Partial<UniverseContextParams>
  ) => {
    const params: FilterParams & UniverseContextParams = {
      itemsPerPage: 48,
      page: page || 1,
      locale,
      ...createFilterParams(filterParams),
      ...createUniverseContextParams(universeParams),
      ...overwriteParams,
    };

    const response = await SyliusAPI.get('/shop/products/listing', {
      params,
      // headers: acceptJSON,
    });

    const result = parseHydraResponse<Product[]>(response.data);

    return {
      ...result,
      data: result.data.map((product) => ({
        ...product,
        brand: getMappedBrand(product.brand, locale),
      })),
    };
  },

  getPopularProductsListing: async (
    categorySlug: string,
    locale: string,
    filterParams?: Partial<AvailableSearchParamValues>
  ) => {
    const response = await SyliusAPI.get('/shop/products/listing', {
      params: {
        popularOffers: 1,
        categorySlug,
        itemsPerPage: 15,
        page: 1,
        locale,
        ...createFilterParams(filterParams),
      },
      headers: acceptJSON,
    });
    return response.data;
  },

  getFlashProductsListing: async (
    categorySlug: string,
    locale: string,
    filterParams?: Partial<AvailableSearchParamValues>
  ) => {
    const response = await SyliusAPI.get('/shop/products/listing', {
      params: {
        'variants.isFlash': 1,
        categorySlug,
        itemsPerPage: 15,
        page: 1,
        locale,
        ...createFilterParams(filterParams),
      },
      headers: acceptJSON,
    });
    return response.data;
  },

  getExploreDiscounts: async (locale: string) => {
    const response = await SyliusAPI.get('/shop/taxons/with-products', {
      params: { isHomePage: true, variantsWithStock: true, locale },
      headers: acceptJSON,
    });

    return response.data;
  },

  getProductVariantBySlug: async (
    id: string,
    locale: string
  ): Promise<ProductVariant> => {
    const response = await SyliusAPI.get(
      `/shop/product-variants/by-slug/${id}`,
      {
        headers: acceptJSON,
        params: {
          locale,
        },
      }
    );
    return response.data;
  },

  getProductVariantsBySlug: async (slug: string, locale: string) => {
    const response = await SyliusAPI.get('/shop/product-variants/listing', {
      params: {
        locale,
        relatedProductVariantsBySlug: slug,
        'order[channelPricings.price]': 'asc',
      },
      headers: acceptJSON,
    });
    return response.data;
  },

  createCart: async () => {
    const response = await SyliusAPI.post('/shop/orders');
    return response.data;
  },

  getCart: async (tokenValue: string) => {
    const response = await SyliusAPI.get(`/shop/orders/${tokenValue}/items`);
    return response.data;
  },

  addProductToCart: async (
    tokenValue: string,
    product: ProductVariant,
    quantity: number
  ) => {
    const response = await SyliusAPI.post(`/shop/orders/${tokenValue}/items`, {
      productVariant: product.code,
      quantity,
    });
    return response.data;
  },

  removeProductFromCart: async (tokenValue: string, itemId: string) => {
    const response = await SyliusAPI.delete(
      `/shop/orders/${tokenValue}/items/${itemId}`
    );
    return response.data;
  },

  getCartListOrders: async () => {
    const response = await SyliusAPI.get('/shop/orders', {
      headers: acceptJSON, // Setting headers to accept JSON response
    });
    return response.data;
  },

  updateCartQuantity: async (
    tokenValue: string,
    itemId: string,
    quantity: number
  ) => {
    const response = await SyliusAPI.patch(
      `/shop/orders/${tokenValue}/items/${itemId}`,
      {
        quantity,
      }
    );
    return response.data;
  },

  getFilterStats: async (
    universeParams: UniverseParams,
    filterParams?: Partial<AvailableSearchParamValues>
  ): Promise<FilterData> => {
    const params = {
      ...createFilterParams(filterParams),
      ...createUniverseContextParams(universeParams),
    } as any;
    const response = await SyliusAPI.get('/shop/products/filters-stats', {
      headers: acceptJSON,
      params,
    });
    return response.data;
  },

  createBackendEvent: async (eventData: BackendEvent) => {
    const response = await SyliusAPI.post('/shop/events/create', eventData, {
      headers: acceptJSON,
    });
    return response.data;
  },
  getBrands: async (brandSlug: string | string[], locale: string) => {
    const response = await SyliusAPI.get('/shop/brands', {
      params: {
        'translations.slug': brandSlug,
        locale,
      },
      headers: acceptJSON,
    });

    return response.data.map((syliusBrand: SyliusBrand) =>
      getMappedBrand(syliusBrand, locale)
    );
  },
  getFavourites: async (locale: string) => {
    const response = await SyliusAPI.get<
      {
        productVariant: ProductVariant;
      }[]
    >('/shop/customer/wishlist', {
      headers: acceptJSON,
      params: { locale },
    });
    return response.data;
  },
};
