import TagManager from 'react-gtm-module';
import { AnalyticsEvents } from '@swibeco/types';

interface BannerPlusEventInterface {
  event: AnalyticsEvents;
  environment: string;
  creativeName: string;
}

export const createBannerPlusEvent = (
  event: AnalyticsEvents,
  environment: string,
  creativeName: string
): BannerPlusEventInterface => ({
  event,
  environment,
  creativeName,
});

export const trackBannerPlusEvent = ({
  event,
  environment,
  creativeName,
}: BannerPlusEventInterface) => {
  TagManager.dataLayer({
    dataLayer: {
      event,
      environment,
      ecommerce: {
        items: [
          {
            promotion_id: 'Plus banner',
            promotion_name: 'Freemium',
            creative_name: creativeName,
            creative_slot: '1',
          },
        ],
      },
    },
  });
};
