import React, { useState } from 'react';
import { AnalyticsContext, Product } from '@swibeco/types';
import {
  useAddToBasket,
  Swiper,
  SwiperCore,
  SwiperSlide,
  ProductItem,
} from '@swibeco/shared-web';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronLeft,
  faChevronRight,
} from '@fortawesome/free-solid-svg-icons';
import { useIsMobile } from '@swibeco/core';

import { isCategoryPage, useUniverseParams } from '@swibeco/ecommerce';
import { Flex } from '@chakra-ui/react';
import * as Styles from './ProductsSwiper.styles';

type ProductsSwiperProps = {
  title: string;
  icon?: string;
  products: Product[];
  spaceBetween?: number;
  config?: {
    numberOfDeals?: {
      mobile?: number;
      tablet?: number;
      desktop?: number;
    };
    numberOfDealsPerPage?: {
      mobile?: number;
      tablet?: number;
      desktop?: number;
    };
  };
  breakpoints?: {
    [width: number]: {
      slidesPerView: number;
      slidesPerGroup: number;
      spaceBetween?: number;
    };
    [ratio: string]: {
      slidesPerView: number;
      slidesPerGroup: number;
      spaceBetween?: number;
    };
  };
  paddingTop?: number;
  marginBottom?: number;
  showControlsOn?: number;
  analytics?: AnalyticsContext;
  mainContent?: boolean;
};

const ProductsSwiper = ({
  icon,
  title,
  products,
  spaceBetween,
  paddingTop,
  marginBottom,
  showControlsOn,
  analytics,
  mainContent,
}: ProductsSwiperProps) => {
  const isMobile = useIsMobile();
  const addToBasket = useAddToBasket();

  const [swiperController, setSwiperController] = useState<SwiperCore | null>(
    null
  );

  const universeParams = useUniverseParams();
  let category: string | undefined;
  let subcategory: string | undefined;
  if (isCategoryPage(universeParams)) {
    category = universeParams.category;
    subcategory = universeParams?.subCategory;
  }

  return (
    <div className="swiper-container">
      <Styles.DecorationShape />
      <Flex
        paddingTop={paddingTop ?? 2}
        marginBottom={marginBottom ?? 2}
        sx={
          mainContent
            ? {
                width: '90%',
                maxWidth: '1440px',
                margin: 'auto',
                paddingTop: 4,
                marginBottom: 4,
              }
            : {}
        }
        className="swiper-header"
        justifyContent="space-between"
      >
        <Flex alignItems="center">
          {icon && (
            <Styles.ExploreDiscountsIcon
              src={icon}
              alt="voucher"
              className="mr-2"
            />
          )}
          <Styles.DealsTitle component="h3" important>
            {title}
          </Styles.DealsTitle>
        </Flex>
        {!isMobile && (
          <Styles.Controls
            size={products.length}
            showControlsOn={showControlsOn}
          >
            <Styles.CircularIconButtons
              type="button"
              className="btn shadow p-auto"
              onClick={() => swiperController?.slidePrev()}
              data-testid="slider-prev-button"
            >
              <FontAwesomeIcon icon={faChevronLeft} size="1x" />
            </Styles.CircularIconButtons>
            <Styles.CircularIconButtons
              type="button"
              className="btn shadow p-auto"
              onClick={() => swiperController?.slideNext()}
              data-testid="slider-next-button"
            >
              <FontAwesomeIcon icon={faChevronRight} size="1x" />
            </Styles.CircularIconButtons>
          </Styles.Controls>
        )}
      </Flex>

      <Styles.SwiperWrapper className="custom-swiper-wrapper">
        <Swiper
          className="pb-4"
          slidesPerView="auto"
          spaceBetween={spaceBetween ?? 18}
          onSwiper={setSwiperController}
          freeMode
          speed={500}
          allowSlideNext
          allowSlidePrev
        >
          {products.map((product) => (
            <SwiperSlide key={product.id}>
              <ProductItem
                product={product}
                onAddItem={addToBasket.addProduct}
                analytics={analytics}
                category={category}
                subcategory={subcategory}
              />
            </SwiperSlide>
          ))}
        </Swiper>
      </Styles.SwiperWrapper>
    </div>
  );
};

export default ProductsSwiper;
