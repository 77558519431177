import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import {
  GlobalSelectors,
  useColorVariant,
  useNumberFormatter,
  useTheme,
  trackBannerPlusEvent,
  createBannerPlusEvent,
  DEFAULT_SORT,
  PLUS_LANDING_PAGE,
  AllResultsSlugs,
  PlusDealSlugs,
} from '@swibeco/shared';
import {
  Swiper,
  SwiperSlide,
  useCanAccessPlusLanding,
} from '@swibeco/shared-web';
import {
  HoverBehavior,
  Link,
  Popover,
  PlusIcon,
  QuestionMark,
  Text,
} from '@swibeco/ui';
import Arrow, {
  ArrowDirection,
} from '@swibeco/ui/lib/components/iconography/Arrow';
import {
  selectors as coreSelectors,
  useSwipoints,
  usePlatform,
} from '@swibeco/core';
import { UserTypeEnum, AnalyticsEvents } from '@swibeco/types';
import { useCurrentCompany } from '@swibeco/security';
import { Box, Flex, Img } from '@chakra-ui/react';
import exploreVouchersImgSrc from '../../assets/images/explore_vouchers.png';
import exploreSwipointsImgSrc from '../../assets/images/explore_swipoints.png';
import * as Styles from './ExploreCallouts.styles';
import axaLogo from '../../assets/images/axa_logo_big.png';

const ExploreCallouts = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const textColor = useColorVariant('default', 'white');
  const hoverBackground = useColorVariant('default', 'white');
  const currencyFormatter = useNumberFormatter();
  const locale = useSelector(coreSelectors.getLocale);
  const swipointsBalance = useSelector(
    GlobalSelectors.user.getSwipointsBalance
  );
  const { plusLandingAccess } = useCanAccessPlusLanding();
  const userType = useSelector(GlobalSelectors.user.getUserType);
  const { swipointsToCashConversion } = useSwipoints(
    swipointsBalance,
    swipointsBalance
  );
  const environment = usePlatform(window);

  const browseLink = useMemo(
    () =>
      `/core/universe/category/${AllResultsSlugs[locale]}?sort=${DEFAULT_SORT}&offerType=voucher`,
    [locale]
  );
  const swipointsLink = useMemo(
    () =>
      `/core/universe/category/${AllResultsSlugs[locale]}?sort=${DEFAULT_SORT}&buyableWithSwipoints=true`,
    [locale]
  );
  const plusTrialLink = useMemo(
    () =>
      plusLandingAccess
        ? PLUS_LANDING_PAGE
        : `product/${PlusDealSlugs[locale]}`,
    [locale, plusLandingAccess]
  );
  const upgradePlusTranslation = t(
    'core.ecommerce.homepage.explore_callouts.upgrade_plus'
  );

  const selectPromotionEventData = createBannerPlusEvent(
    AnalyticsEvents.SELECT_PROMOTION,
    environment,
    upgradePlusTranslation
  );

  const { company } = useCurrentCompany();
  const axaCompanyUrl = company?.dealer_is_axa ? 'universe/brand/axa' : '';

  return (
    <Styles.Container>
      <Swiper
        className="pt-4"
        freeMode
        spaceBetween={30}
        data-testid="explore-callouts"
      >
        <SwiperSlide key="explore-callouts-browse">
          <Link to={browseLink} data-testid="explore-callouts-browse">
            <Styles.Callout background={theme.initial!.colors.primary.main}>
              <Styles.Image src={exploreVouchersImgSrc} />
              <Text className="mr-auto" color={textColor} bold>
                {t('core.ecommerce.homepage.explore_callouts.browse_vouchers')}
              </Text>
              <HoverBehavior
                color={hoverBackground}
                className="mr-1"
                opacity={0.2}
              >
                <Arrow
                  className="m-1"
                  color={textColor}
                  direction={ArrowDirection.Right}
                />
              </HoverBehavior>
            </Styles.Callout>
          </Link>
        </SwiperSlide>
        {swipointsBalance > 0 && (
          <SwiperSlide key="explore-callouts-swipoints">
            <Link to={swipointsLink} data-testid="explore-callouts-swipoints">
              <Styles.Callout background={theme.initial!.colors.primary.light}>
                <Styles.Image src={exploreSwipointsImgSrc} />
                <div className="d-flex flex-wrap align-items-center mr-auto">
                  <Text color="default.white" maxW="80%" mr={2} bold>
                    {t(
                      'core.ecommerce.homepage.explore_callouts.use_swipoints'
                    )}
                  </Text>
                  <Popover
                    placement="top"
                    popoverTrigger={
                      <QuestionMark
                        id="explore-callouts-swipoints-question-mark"
                        width={20}
                        height={19.35}
                      />
                    }
                  >
                    <Text>
                      <strong>
                        {t(
                          'core.checkout.funnel.swipoints_payment.information.title'
                        )}
                      </strong>
                    </Text>
                    <Text>
                      {t(
                        'core.checkout.funnel.swipoints_payment.information.description'
                      )}
                    </Text>
                  </Popover>
                  <Text className="w-100" color={textColor} bold>
                    {currencyFormatter(swipointsToCashConversion)}
                  </Text>
                </div>
                <HoverBehavior
                  color={hoverBackground}
                  className="mr-1"
                  opacity={0.2}
                >
                  <Arrow
                    className="m-1"
                    color={textColor}
                    direction={ArrowDirection.Right}
                  />
                </HoverBehavior>
              </Styles.Callout>
            </Link>
          </SwiperSlide>
        )}
        {userType === UserTypeEnum.FREE && (
          <SwiperSlide key="explore-callouts-upgrade">
            <Link
              to={plusTrialLink}
              data-testid="explore-callouts-upgrade"
              onClick={() => trackBannerPlusEvent(selectPromotionEventData)}
            >
              {/* TODO change href to final slug before release */}
              <Styles.Callout background={theme.colors.primary.plusBlue}>
                <Styles.PlusIconWrapper>
                  <PlusIcon width={46} height={62} />
                </Styles.PlusIconWrapper>
                <Text className="mr-auto" color={textColor} bold>
                  {upgradePlusTranslation}
                </Text>
                <HoverBehavior
                  color={hoverBackground}
                  className="mr-1"
                  opacity={0.2}
                >
                  <Arrow
                    className="m-1"
                    color={textColor}
                    direction={ArrowDirection.Right}
                  />
                </HoverBehavior>
              </Styles.Callout>
            </Link>
          </SwiperSlide>
        )}
        {company?.dealer_is_axa && (
          <SwiperSlide key="explore-callouts-axa">
            <Link
              to={axaCompanyUrl}
              data-testid="explore-callouts-axa-link"
              // onClick={() => trackBannerPlusEvent(selectPromotionEventData)}
            >
              <Flex
                alignItems="center"
                borderRadius="3px"
                bgColor="primary.axaBlue"
                gap="10px"
                height="72px"
                padding="5px"
                width="298px"
              >
                <Box
                  width={62}
                  minWidth={62}
                  height={62}
                  borderColor="white"
                  borderWidth="4px"
                  borderRadius="3px"
                >
                  <Img src={axaLogo} w="100%" />
                </Box>
                <Text className="mr-auto" color={textColor} bold>
                  {t('core.ecommerce.homepage.explore_callouts.axa')}
                </Text>
                <HoverBehavior
                  color={hoverBackground}
                  className="mr-1"
                  opacity={0.2}
                >
                  <Arrow
                    className="m-1"
                    color={textColor}
                    direction={ArrowDirection.Right}
                  />
                </HoverBehavior>
              </Flex>
            </Link>
          </SwiperSlide>
        )}
      </Swiper>
    </Styles.Container>
  );
};

export default ExploreCallouts;
