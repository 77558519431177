import * as yup from 'yup';
import i18next from 'i18next';
import coreApi from './api/coreApi';
import checkoutAPI from './api/checkoutAPI';

export * from './coreSlice';
export * from './hooks';
export * from './types';
export * from './components';
export * from './store';

yup.setLocale({
  mixed: {
    oneOf: () => i18next.t('forms.errors.one_of'),
    required: () => i18next.t('forms.error.required'),
    notType: () => i18next.t('forms.error.invalid'),
  },
  string: {
    email: () => i18next.t('forms.error.valid_email'),
    matches: () => i18next.t('form.errors.string.matches'),
  },
  number: {
    min: ({ min }) => i18next.t('forms.error.min_number', { min }),
    max: ({ max }) => i18next.t('forms.error.max_number', { max }),
  },
});

export { coreApi, checkoutAPI };
