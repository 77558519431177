import React from 'react';
import { Caption, Link } from '@swibeco/ui';
import { ECOMMERCE_CORE } from '@swibeco/shared';
import { Product } from '@swibeco/types';
import { Box } from '@chakra-ui/react';
import * as Styles from './styles/SearchItem.styles';
import ProductImage from '../../ProductItem/ProductImage';
import ProductPrice from '../../ProductItem/ProductPrice';

const SearchItem = ({
  product,
  toggleSearchResults,
}: {
  product: Product;
  toggleSearchResults: () => void;
}) => (
  <Link
    to={`${ECOMMERCE_CORE}/product/${
      product?.defaultVariantData.slug || product?.slug
    }`}
    onClick={toggleSearchResults}
  >
    <Styles.SearchItemWrapper>
      <Styles.ProductImageWrapper>
        <ProductImage
          isStandalone
          product={product}
          displayBrand={false}
          isFlash={false}
          isProductNew={false}
        />
      </Styles.ProductImageWrapper>
      <div>
        <Caption w="full">
          {product?.brand && `${product?.brand.translationsMap?.name} - `}
          {product?.defaultVariantData?.shortName}
        </Caption>
        <Box transform="scale(0.85)" transformOrigin="center left">
          <ProductPrice product={product} />
        </Box>
      </div>
    </Styles.SearchItemWrapper>
  </Link>
);

export default SearchItem;
