import { Flex, Box, Img, Spacer } from '@chakra-ui/react';
import { UserType } from '@swibeco/types';
import React from 'react';
import { useIsMobile, useIsTablet } from '@swibeco/core';
import { AnimatePresence, motion } from 'framer-motion';
import { Text } from '@swibeco/ui';
import { useCurrentCompany } from '@swibeco/security';
import { useTranslation } from 'react-i18next';
import BottomSheet from '../../../BottomSheet/BottomSheet';
import { HeaderPanel } from './Header';
import { HelpCenter } from './HelpCenter';
import Logout from './Logout/Logout';
import { PersonalCorner } from './PersonalCorner';
import { OrderHistory } from './OrderHistory';
import axaLogoImg from '../../../../assets/images/axa-logo.png';
// import { Savings } from './Savings';

type UserProfilePanel = {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  userData: UserType | undefined;
};
const SidePanel = ({ show, children, ...rest }: any) => (
  <AnimatePresence>
    {show && (
      <motion.div
        key="panel-animation"
        transition={{
          duration: 0.7,
        }}
        initial={{ x: '100vw' }}
        animate={{ x: show ? 0 : '100vw' }}
        exit={{
          x: '100vw',
          transition: {
            duration: 2,
          },
        }}
        style={{
          borderRight: '1px solid',
          position: 'fixed',
          right: 0,
          height: '100%',
          width: '400px',
          zIndex: 9999,
          top: 0,
          display: 'block',
        }}
        {...rest}
      >
        {children}
      </motion.div>
    )}
  </AnimatePresence>
);
const PanelContent = ({ userData, closeBottomSheet }: any) => {
  const { company } = useCurrentCompany();
  const { t } = useTranslation();
  return (
    <Flex direction="column" gap="32px" p="28px" h="100%">
      <HeaderPanel closeBottomSheet={closeBottomSheet} userData={userData} />
      {/* this componenet is ui integrated and disable reserved for v2 waiting for backend */}
      {/* <Savings /> */}
      {/* this componenet is ui integrated but the backend api doesn't support all the features */}
      <OrderHistory closeBottomSheet={closeBottomSheet} />
      <PersonalCorner userData={userData} closeBottomSheet={closeBottomSheet} />
      <HelpCenter closeBottomSheet={closeBottomSheet} />
      <Flex align="center" justify="center">
        <Logout closeBottomSheet={closeBottomSheet} />
      </Flex>
      <Spacer />
      {company?.dealer_is_axa && (
        <Flex gap="16px" alignItems="center" justifyContent="center">
          <Text color="primary.dark">{t('core.ecommerce.powered_by')}</Text>
          <Img src={axaLogoImg} alt="AXA" />
        </Flex>
      )}
    </Flex>
  );
};
const Panel = ({ isOpen, setIsOpen, userData }: UserProfilePanel) => {
  const isMobile = useIsMobile();
  const isTablet = useIsTablet();
  const closeBottomSheet = () => {
    setIsOpen(false);
  };
  if (!userData) return null;
  if (isMobile || isTablet) {
    return (
      <BottomSheet
        panelKey="user-profile"
        show={isOpen}
        buttonDestroy={false}
        onDidDismiss={() => {
          closeBottomSheet();
        }}
      >
        <PanelContent closeBottomSheet={closeBottomSheet} userData={userData} />
      </BottomSheet>
    );
  }

  return (
    <>
      <AnimatePresence>
        {isOpen && (
          <motion.div
            key="panel-animation"
            data-testid="outside-panel"
            transition={{
              duration: 0.7,
            }}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{
              opacity: 0,
              transition: {
                duration: 1,
              },
            }}
            style={{
              position: 'fixed',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundColor: 'rgba(0, 0, 0, 0.4)',
              display: isOpen ? 'block' : 'none',
              justifyContent: 'start',
              alignItems: 'stretch',
              zIndex: 9999,
            }}
            onClick={() => {
              closeBottomSheet();
            }}
          />
        )}
      </AnimatePresence>
      <SidePanel
        panelKey="user-profile-desktop"
        data-testid="panel"
        show={isOpen}
        buttonDestroy={false}
        onDidDismiss={() => {
          closeBottomSheet();
        }}
      >
        <Box
          style={{
            overflow: 'auto',
            width: '400px',
            height: '100%',
            backgroundColor: 'white',
            position: 'absolute',
            right: 0,
          }}
        >
          <PanelContent
            closeBottomSheet={closeBottomSheet}
            userData={userData}
          />
        </Box>
      </SidePanel>
    </>
  );
};
export default Panel;
