import { MappedProductVariant, ProductVariant } from '@swibeco/types';

export const calculateCashback = (
  deal: MappedProductVariant | undefined,
  quantity: number
) =>
  deal?.cashbackAmount ? ((deal?.cashbackAmount ?? 0) / 100) * quantity : 0;

export const calculateProductsCashbackPercentage = (
  deals: MappedProductVariant[],
  quantity: Record<string, number>
): number => {
  const total = deals.reduce((acc, deal: MappedProductVariant) => {
    const productQuantity = quantity[deal.slug] ?? 0;
    return acc + calculateCashback(deal, productQuantity);
  }, 0);
  return total;
};

export const calculateProductsCashback = (
  deals: MappedProductVariant[],
  quantity: Record<string, number>
): number => {
  const total = deals.reduce((acc, deal: MappedProductVariant) => {
    const productQuantity = quantity[deal.slug] ?? 0;
    return acc + calculateCashback(deal, productQuantity);
  }, 0);
  return total;
};

export const calculateFinalPrice = (
  deal: MappedProductVariant | undefined,
  quantity: number
) =>
  deal?.channelPricing
    ? ((deal.channelPricing.price ?? 0) / 100) * quantity
    : 0;

export const calculateProductsFinalPrice = (
  products: MappedProductVariant[],
  quantity: Record<string, number>
) => {
  const total = products.reduce((acc, product) => {
    const productQuantity = quantity[product.slug] ?? 0;
    return acc + calculateFinalPrice(product, productQuantity);
  }, 0);
  return total;
};

export const calculateListPrice = (
  deal: MappedProductVariant,
  quantity: number
) =>
  deal?.channelPricing
    ? ((deal.channelPricing.originalPrice !== undefined &&
      deal.channelPricing.originalPrice !== 0
        ? deal.channelPricing.originalPrice
        : deal.channelPricing.price) /
        100) *
      quantity
    : 0;

export const calculateSavings = (
  deal: MappedProductVariant | undefined,
  quantity: number
) =>
  deal?.channelPricing
    ? calculateListPrice(deal, quantity) - calculateFinalPrice(deal, quantity)
    : 0;
export const calculateProductsListSavings = (
  products: MappedProductVariant[],
  quantity: Record<string, number>
) => {
  const total = products.reduce((acc, product) => {
    const productQuantity = quantity[product.slug] ?? 0;
    return acc + calculateSavings(product, productQuantity);
  }, 0);
  return total;
};
export const calculateProductListPrice = (
  products: MappedProductVariant[],
  quantity: Record<string, number>
) => {
  const total = products.reduce((acc, product) => {
    const productQuantity = quantity[product.slug] ?? 0;
    return acc + calculateListPrice(product, productQuantity);
  }, 0);
  return total;
};

export const calculateProductsListPrice = (
  deals: MappedProductVariant[],
  quantity: Record<string, number>
): number => {
  const total = deals.reduce((acc, deal: MappedProductVariant) => {
    const productQuantity = quantity[deal.slug] ?? 0;
    return acc + calculateListPrice(deal, productQuantity);
  }, 0);
  return total;
};

export const calculateVouchersListPrice = (
  products: MappedProductVariant[],
  quantity: Record<string, number>
) => {
  const total = products.reduce((acc, product) => {
    const productQuantity = quantity[product.slug] ?? 0;
    return acc + calculateListPrice(product, productQuantity);
  }, 0);
  return total;
};

export const getDiscountRate = (
  product?: ProductVariant | MappedProductVariant,
  toString: boolean = true
): number | string => {
  const discount = toString ? '' : 0;
  const productTypes = [
    'physical',
    'dropshipping',
    'voucher',
    'swipoints_pack',
    'subscription',
  ];
  const productPrice = (product?.channelPricing?.price || 0) / 100;
  const originalPrice = (product?.channelPricing?.originalPrice || 0) / 100;
  if (productTypes.includes(product?.product.type)) {
    if (product?.product.isExternalPayment) return discount;

    const vatRate = product?.variantVatRate ?? 0;
    const priceWithoutVat = productPrice - productPrice * vatRate;
    const originalWithoutPriceVat = originalPrice - originalPrice * vatRate;
    if (
      priceWithoutVat === null ||
      priceWithoutVat === 0 ||
      originalWithoutPriceVat === null ||
      originalWithoutPriceVat === 0
    ) {
      return toString ? '100%' : 1;
    }
    const discountRate =
      priceWithoutVat !== null &&
      originalWithoutPriceVat !== null &&
      originalWithoutPriceVat > 0
        ? 1 - priceWithoutVat / originalWithoutPriceVat
        : 0;
    return toString
      ? `${Math.round(discountRate * 100)}%`
      : Math.round(discountRate * 100) / 100;
  }
  return discount;
};
export const calculateProductListDiscount = (
  products: MappedProductVariant[],
  quantity: Record<string, number>
): string => {
  let totalDiscount = 0;
  let totalOriginalPrice = 0;

  products.forEach((product) => {
    const discountRate = getDiscountRate(product, false);
    const productQuantity = quantity[product.slug] ?? 0;
    const originalPrice = (product?.channelPricing?.originalPrice || 0) / 100;
    totalDiscount += (discountRate as number) * originalPrice * productQuantity;
    totalOriginalPrice += originalPrice * productQuantity;
  });

  const discountPercentage =
    totalOriginalPrice === 0 ? 0 : totalDiscount / totalOriginalPrice;
  return `${(discountPercentage * 100).toFixed(2)}%`;
};
export const inStock = (deal?: MappedProductVariant | ProductVariant) =>
  (deal?.onHand ?? 0) - (deal?.onHold ?? 0) > 0 || deal?.tracked === false;
