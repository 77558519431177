import React from 'react';
import { styled, breakpoints } from '@swibeco/shared';
import {
  Input as RSInput,
  InputProps as RSInputProps,
  FormFeedback,
} from 'reactstrap';
import { ErrorMessage } from './ErrorMessage';

const InputDesign = styled(RSInput, { shouldForwardProp: () => true })`
  height: 45px;
  font-size: 17px;
  font-weight: 500;
  line-height: 21px;
  border: 1px solid ${({ theme }) => theme.colors.default.middle};
  border-radius: 3px !important;
  color: ${({ theme }) => theme.colors.default.black};

  ${breakpoints.lg} {
    height: 40px;
    font-size: 18px;
    line-height: 24px;
  }

  &.is-invalid {
    color: ${({ theme }) => theme.colors.default.black};
    border: ${({ theme }) => `1px solid ${theme.colors.secondary.main}`};
    background-position: 92% center;
  }

  &:hover {
    border: ${({ theme }) => `1px solid ${theme.colors.default.strong}`};
  }

  &:active,
  &:focus {
    color: ${({ theme }) => theme.colors.default.black};
    border: ${({ theme }) => `1px solid ${theme.colors.primary.main}`};

    &.is-invalid {
      color: ${({ theme }) => theme.colors.default.black};
      border: ${({ theme }) => `1px solid ${theme.colors.secondary.main}`};
    }
  }

  &:disbabled {
    background-color: ${({ theme }) => theme.colors.default.strong};
  }
`;

export interface InputProps extends RSInputProps {
  name: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  errors?: any;
  isTouched?: boolean;
}

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  ({ name, errors, isTouched = true, ...rest }, ref) => (
    <>
      <InputDesign
        name={name}
        {...rest}
        innerRef={ref}
        invalid={Boolean(errors && errors[name]?.message && isTouched)}
      />

      <FormFeedback className="d-flex">
        {errors && isTouched && (
          <ErrorMessage data-testid="error-msg">
            {errors[name]?.message}
          </ErrorMessage>
        )}
      </FormFeedback>
    </>
  )
);

Input.displayName = 'Input';

export default Input;
