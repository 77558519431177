import { useParams } from 'react-router-dom';

export enum UniverseContextValues {
  universe = 'universe',
  category = 'category',
  event = 'event',
  brand = 'brand',
  mycompanybenefits = 'mycompanybenefits',
  search = 'search',
}

type IConfigurationObject = Record<
  UniverseContextValues,
  {
    contextValue: string;
    subSection?: string;
  }
>;
const configurationObject: IConfigurationObject = {
  category: {
    contextValue: 'category' as const,
    subSection: 'subCategory' as const,
  },
  event: {
    contextValue: 'event' as const,
  },
  brand: {
    contextValue: 'brand' as const,
  },
  universe: {
    contextValue: 'universe' as const,
  },
  mycompanybenefits: {
    contextValue: 'mycompanybenefits' as const,
  },
  search: {
    contextValue: 'search' as const,
  },
};

export type CategoryContext = {
  context: 'category';
  category: string;
  subCategory?: string;
  // priority: ['subCategory', 'category'];
};

export type EventContext = {
  context: 'event';
  event: string;
};

export type BrandContext = {
  context: 'brand';
  brand: string;
};

export type UniverseContext = {
  context: 'universe';
  universe: string;
};

export type SearchContext = {
  context: 'search';
  search: string;
};

export type ApiUniverseParams = {
  apiValue: {
    value: string;
    context: UniverseContextValues;
  };
};

export type InhousedealsContext = {
  context: 'mycompanybenefits';
};

export type UniverseParams = ApiUniverseParams &
  (
    | CategoryContext
    | EventContext
    | BrandContext
    | UniverseContext
    | InhousedealsContext
    | SearchContext
  );

type IUseUniverseParams = {
  context: UniverseContextValues;
  contextValue: string;
  subSection?: string;
  values?: string; // slash separated string of further nested values: (context/contextValue/) "subSection/value1/value2/value3"
};

const returnValue = (param: UniverseParams) => {
  switch (param.context) {
    case 'category':
      return {
        value: param.subCategory ?? param.category,
        context: param.context,
      };
    case 'event':
      return {
        value: param,
        context: param.context,
      };
    case 'brand':
      return {
        value: param[UniverseContextValues.brand],
        context: param.context,
      };
    case 'universe':
      return {
        value: param[UniverseContextValues.universe],
        context: param.context,
      };
    case 'mycompanybenefits':
      return {
        context: param.context,
      };
    case 'search':
      return {
        value: param[UniverseContextValues.search],
        context: param.context,
      };
    default:
      throw new Error("Context doesn't exist");
  }
};

export const useUniverseParams = (): UniverseParams => {
  const universeParams = useParams<IUseUniverseParams>();
  const { contextValue, subSection, values } = universeParams;
  // in order to use the values, we need to split the string into an array
  const additionalInfoValues = {
    contextValue,
    subSection,
    values,
  };
  const context = universeParams?.['*']?.split('/')[1] || universeParams?.['*'];

  if (!context) {
    return {} as UniverseParams;
  }
  if (!configurationObject[context]) {
    return {} as UniverseParams;
  }
  const additionalInfo: any = Object.keys(configurationObject[context]).reduce(
    (acc, key) => ({
      ...acc,
      [configurationObject[context][key]]: additionalInfoValues[key],
    }),
    {}
  );
  const params = {
    context,
    ...additionalInfo,
  };
  return {
    ...params,
    apiValue: returnValue(params),
  };
};
