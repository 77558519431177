import {
  StyleFunctionProps,
  createMultiStyleConfigHelpers,
  defineStyle,
  defineStyleConfig,
  extendBaseTheme,
} from '@chakra-ui/react';
import { theme as chakraTheme } from '@chakra-ui/react';
import { tabsAnatomy } from '@chakra-ui/anatomy';
import { breakpointsSizes } from '@swibeco/shared';
import { SwibecoColors } from './colors';

const { Button, Menu, Popover, Card, Modal } = chakraTheme.components;

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(tabsAnatomy.keys);

export const customTheme = extendBaseTheme({
  zIndices: {
    modal: '2000',
  },
  radii: {
    small: '3px',
    mid: '5px',
  },
  components: {
    Popover,
    Button,
    Menu,
    Modal,
    Card,
    MenuItem: {
      baseStyle: {
        _hover: { bg: 'default.backgroundColor' },
        _focus: { bg: 'default.backgroundColor' },
        _selected: { bg: 'default.backgroundColor' },
        _pressed: { bg: 'default.backgroundColor' },
      },
    },
    Text: defineStyleConfig({
      variants: {
        default: defineStyle(() => {
          return {
            fontSize: {
              base: '16px',
              lg: '17px',
            },
            lineHeight: {
              base: '22px',
              lg: '23px',
            },
            fontWeight: 500,
            mb: 0,
          };
        }),
        'default-important': defineStyle(() => {
          return {
            fontSize: {
              base: '18px',
              lg: '20px',
            },
            lineHeight: {
              base: '24px',
              lg: '26px',
            },
            fontWeight: 700,
            mb: 0,
          };
        }),
        decoration: defineStyle(() => {
          return {
            fontSize: {
              base: '26px',
              lg: '36px',
            },
            lineHeight: {
              base: '36px',
              lg: '50px',
            },
            fontWeight: 500,
            mb: 0,
          };
        }),
        'decoration-important': defineStyle(() => {
          return {
            fontSize: {
              base: '26px',
              lg: '36px',
            },
            lineHeight: {
              base: '36px',
              lg: '50px',
            },
            fontWeight: 700,
            mb: 0,
          };
        }),
        'section-title': defineStyle((props: StyleFunctionProps) => {
          const { color } = props;
          return {
            color: color || 'default.black',
            fontSize: {
              base: '20px',
              lg: '22px',
            },
            lineHeight: {
              base: '27px',
              lg: '29px',
            },
            fontWeight: 500,
            mb: 0,
          };
        }),
        lined: defineStyle((props: StyleFunctionProps) => {
          const { color } = props;
          return {
            color: color || 'default.dark',
            textDecoration: 'line-through',
            fontWeight: 400,
            fontSize: {
              base: '16px',
              lg: '15px',
            },
            lineHeight: '21px',
            letterSpacing: '0.24px',
            mb: 0,
          };
        }),
        'lined-important': defineStyle((props: StyleFunctionProps) => {
          const { color } = props;
          return {
            color: color || 'default.dark',
            textDecoration: 'line-through',
            fontWeight: 400,
            fontSize: {
              base: '18px',
              lg: '20px',
            },
            lineHeight: '21px',
            letterSpacing: '0.24px',
            mb: 0,
          };
        }),
        caption: defineStyle((props: StyleFunctionProps) => {
          const { color } = props;
          return {
            color: color || 'default.black',
            fontSize: {
              base: '13px',
              lg: '15px',
            },
            lineHeight: {
              base: '17px',
              lg: '21px',
            },
            fontWeight: 500,
            margin: 0,
          };
        }),
        legal: defineStyle((props: StyleFunctionProps) => {
          const { color } = props;
          return {
            color: color || 'default.dark',
            fontSize: {
              base: '11px',
              lg: '13px',
            },
            lineHeight: {
              base: '14px',
              lg: '18px',
            },
            fontWeight: 500,
            margin: 0,
          };
        }),
        'text-link': defineStyle((props: StyleFunctionProps) => {
          const { color } = props;
          return {
            color: color || 'default.black',
            fontSize: {
              base: '16px',
              lg: '17px',
            },
            lineHeight: {
              base: '22px',
              lg: '23px',
            },
            mb: 0,
            textDecoration: 'underline',
            _hover: { color: 'primary.main', textDecoration: 'underline' },
          };
        }),
        'mobile-important': defineStyle((props: StyleFunctionProps) => {
          const { color } = props;
          return {
            color: color || 'default.black',
            fontSize: {
              base: '16px',
            },
            lineHeight: {
              base: '22px',
            },
            mb: 0,
            fontWeight: 700,
          };
        }),
      },
    }),
    Heading: defineStyleConfig({
      variants: {
        'sub-title': defineStyle((props: StyleFunctionProps) => {
          const { color } = props;
          return {
            color: color || 'default.black',
            fontSize: {
              base: '24px',
              lg: '32px',
            },
            lineHeight: {
              base: '33px',
              lg: '41px',
            },
            fontWeight: 700,
            margin: 0,
          };
        }),

        'tertiary-title': defineStyle((props: StyleFunctionProps) => {
          const { color } = props;
          return {
            color: color || 'default.black',
            fontSize: {
              base: '22px',
              lg: '26px',
            },
            lineHeight: {
              base: '30px',
              lg: '33px',
            },
            fontWeight: 500,
            margin: 0,
          };
        }),

        'page-title': defineStyle((props: StyleFunctionProps) => {
          const { color } = props;
          return {
            color: color || 'primary.main',
            fontSize: {
              base: '29px',
              lg: '36px',
            },
            lineHeight: {
              base: '53px',
              lg: '50px',
            },
            fontWeight: 700,
            margin: 0,
          };
        }),
      },
    }),
    Tabs: defineMultiStyleConfig({
      variants: {
        rounded: definePartsStyle({
          tab: {
            display: 'flex',
            gap: '4px',
            alignItems: 'center',
            borderRadius: 'full',
            p: '8px 16px',
            color: 'default.black',
            _hover: {
              bgColor: 'default.middle',
            },
            _selected: {
              bgColor: 'primary.main',
              color: 'default.white',
              boxShadow: '0px 3px 6px 0px rgba(62, 97, 145, 0.20)',
            },
          },
          tablist: {
            p: '8px 16px',
            w: '100%',
            borderRadius: { base: '0', md: '53px' },
            bgColor: 'default.main',
            gap: '16px',
          },
          tabpanel: {
            mt: '16px',
            p: { base: '11px 17px', lg: '24px 39px' },
            bgColor: 'default.white',
            borderStyle: 'solid',
            borderWidth: '1px',
            borderColor: 'default.main',
          },
        }),
      },
    }),
  },
  colors: SwibecoColors.colors,
  breakpoints: breakpointsSizes,
});
